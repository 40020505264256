import { useEffect, useState, FC } from "react";
import Image from "next/image";
import { CarouselImageType } from "../../types";
import { useIsMounted } from "../../lib/use-is-mounted";

type Props = {
  images: Array<CarouselImageType>;
  opacity?: string;
  overlay?: boolean;
  overlayText?: string;
};

export const Carousel: FC<Props> = ({
  images,
  opacity = "100",
}) => {
  const isMounted = useIsMounted();
  const [imageIndex, setImageIndex] = useState(0);

  useEffect(() => {
    let index = 1;
    const interval = setInterval(() => {
      if (index === images.length) {
        setImageIndex(0);
        index = 1;
      } else {
        setImageIndex(index);
        index = index + 1;
      }
    }, 4500);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="relative w-full h-full aspect-h-2 aspect-w-3 md:hidden">
        {images.map(({ url, altText }, index) => {
          return (
            <Image
              key={url + index}
              src={url}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              className={`${
                imageIndex === index ? `opacity-${opacity}` : "opacity-0"
              } ${
                isMounted
                  ? "transition-all ease-in-out transform duration-2000"
                  : "opacity-1!"
              }`}
              priority
              alt={altText}
            />
          );
        })}
      </div>
      <div className="relative w-full h-full aspect-h-2 aspect-w-3 hidden md:flex">
        {images.map(({ url, altText }, index) => {
          return (
            <Image
              key={url + index}
              src={url}
              layout="fill"
              objectFit="cover"
              objectPosition="center"
              className={`${
                imageIndex === index ? `opacity-${opacity}` : "opacity-0"
              } ${
                isMounted
                  ? "transition-all ease-in-out transform duration-2000"
                  : null
              }`}
              priority
              alt={altText}
            />
          );
        })}
      </div>
    </>
  );
};
