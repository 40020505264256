import { FC, PropsWithChildren } from "react";
import { motion } from "framer-motion";
import { pageVariants } from "../../utils/variants";

export const Layout: FC<PropsWithChildren<unknown>> = ({ children }) => {
  return (
    <motion.main
      className="flex flex-col items-center w-full flex-1 tracking-wide"
      variants={pageVariants}
      transition={{ type: "ease-in-out", duration: 1.2 }}
      initial="hidden"
      animate="enter"
      exit="exit"
    >
      {children}
    </motion.main>
  );
};
