import { CarouselImageType, ImageAttributesType, PictureType } from "../types";

export const picturesMapper = (
  pictures: Array<ImageAttributesType>
): Array<CarouselImageType> => {
  return pictures?.map((picture) => {
    return {
      url: `${picture.attributes.hash}${picture.attributes.ext}`,
      altText: picture.attributes.alternativeText,
    };
  });
};

export const pictureMapper = (
  picture: PictureType | undefined
): CarouselImageType => {
  return {
    url: `${picture?.data?.attributes?.hash}${picture?.data?.attributes?.ext}`,
    altText: picture?.data?.attributes?.alternativeText || "",
  };
};
