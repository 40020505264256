import { WorkshopType } from "../types";

const sortDates = (dates: Array<WorkshopType>) => {
  return dates
    .map(({ attributes: { date } }) => new Date(date).getTime())
    .sort((a, b) => a - b);
};

export const getNextWorkshop = (
  workshops: Array<WorkshopType> = []
): WorkshopType => {
  const nextDate = sortDates(workshops).filter(
    (date) => Date.now() - date < 0
  )[0];

  return (
    workshops.find(
      ({ attributes: { date } }) => nextDate === new Date(date).getTime()
    ) || workshops[0]
  );
};
