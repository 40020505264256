import Link from "next/link";
import Image from "next/image";
import { VerticalCardType } from "../../types";
import { FC } from "react";
import { format } from "date-fns";

export const VerticalCard: FC<VerticalCardType> = ({
  title,
  link = "",
  date,
  picture: { url, altText },
}) => {
  const isExpired = Date.now() - new Date(date).getTime() > 0;
  return (
    <Link href={link} passHref>
      <article className="w-full cursor-pointer h-4/5">
        <div
          className={`relative w-full h-4/5 aspect-h-3 aspect-w-2 ${
            isExpired ? "grayscale" : ""
          }`}
        >
          <Image
            src={url}
            layout="fill"
            objectFit="cover"
            objectPosition="center"
            priority
            alt={altText}
          />
        </div>
        <div className="flex flex-col align-center text-gray">
          <p>Data: {format(new Date(date), "dd-MM-yyyy")}</p>
          <p>Taller: {title}</p>
        </div>
      </article>
    </Link>
  );
};
