import type { GetStaticProps, NextPage } from "next";
import { fetchAPI } from "../lib/api";
import { Layout } from "../components/layout";
import { HomeTypeAttributes } from "../types";
import { SeoComponent } from "../components/seo-component";
import { Carousel } from "../components/carousel";
import { pictureMapper, picturesMapper } from "../mapper";
import ReactMarkdown from "react-markdown";
import { getNextWorkshop } from "../utils/get-next-workshop";
import { VerticalCard } from "../components/vertical-card";

const Home: NextPage<HomeTypeAttributes> = ({ text, pictures, workshops }) => {
  const images = picturesMapper(pictures?.data);
  const nextWorkshop = getNextWorkshop(workshops);

  return (
    <Layout>
      <div className="-z-10 fixed top-0 left-0 h-full w-full bg-[url('/logo-negre.png')] bg-center bg-contain bg-no-repeat before:content-[''] before:absolute before:inset-0 before:bg-white before:bg-opacity-[.97]" />
      <div className="w-5/6 sm:w-4/5 lg:w-3/4 xl:w-2/3 2xl:w-[45%] h-full flex flex-col pt-18">
        <SeoComponent />
        <div className="w-full flex flex-col justify-start bg-white">
          <h1 className="font-title text-orange text-4xl">La Sangha</h1>
          {images && images.length > 0 ? (
            <div className="w-full mb-4 self-center">
              <Carousel images={images} />
            </div>
          ) : null}
        </div>
        <div className="w-full flex flex-col justify-start">
          <div className="max-w-none prose text-sm my-4">
            <ReactMarkdown>{text}</ReactMarkdown>
          </div>

          {nextWorkshop ? (
            <div className="h-full flex flex-col items-center mt-8 mb-16 ">
              <h2 className="font-title text-blue text-2xl mb-2">PRÒXIM TALLER</h2>
              <div className="w-4/5 md:w-3/5 lg:w-2/5">
                <VerticalCard
                  link={`/que-fem/esdeveniments/${nextWorkshop.attributes.slug}`}
                  title={nextWorkshop.attributes.title}
                  date={nextWorkshop.attributes.date}
                  picture={pictureMapper(nextWorkshop.attributes.picture)}
                />
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Layout>
  );
};

export const getStaticProps: GetStaticProps = async () => {
  const { data = {} } = await fetchAPI("/api/home?populate=*");
  const { data: workshops = [] } = await fetchAPI(
    "/api/workshops?populate=*&sort=date:asc"
  );

  return {
    props: {
      text: data?.attributes?.text,
      pictures: data?.attributes.pictures,
      workshops,
    },
    revalidate: 30,
  };
};

export default Home;
